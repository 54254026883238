
.header{
   
    display: flex;
    align-items: center;
    background-color:#021430 ;
    height: 16%;
    padding: 8px 8px;
    color: whitesmoke;
    justify-content: space-between;

}
.header > .logo {
    height: 120px;
    margin:6px 6px;
    background-color: whitesmoke

}
.header--title{
    font-size: 20px
}

@media   screen and (min-height: 48px) {
    .header--title {
      font-size: 6px;
    }
    .header > .logo {
        height: 12px;
        margin:6px 6px;
    
    }
}
@media   screen and (min-height: 64px) {
    .header--title {
      font-size: 8px;
    }
    .header > .logo {
        height: 14px;
        margin:6px 6px;
    
    }
   
  }

  @media   screen and (min-height: 96px) {
    .header--title {
      font-size: 16px;
    }
    .header > .logo {
        height: 16px;
        margin:6px 6px;
    
    }
   
  }

  @media   screen and (min-height: 128px) {
    .header--title {
      font-size: 20px;
    }
    .header > .logo {
        height: 20px;
        margin:6px 6px;
    
    }
   
  }
@media   screen and (min-height: 192px) {
    .header--title {
      font-size: 24px;
    }
    .header > .logo {
        height: 30px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 256px) {
    .header--title {
      font-size: 26px;
    }
    .header > .logo {
        height: 36px;
        margin:6px 6px;
    
    }
   
  }

  @media   screen and (min-height: 320px) {
    .header--title {
      font-size: 24px;
    }
    .header > .logo {
        height: 38px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 384px) {
    .header--title {
      font-size: 24px;
    }
    .header > .logo {
        height: 50px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 448px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 60px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 512px) {
    .header--title {
      font-size: 30px;
    }
    .header > .logo {
        height:70px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 600px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 80px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 700px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 90px;
        margin:6px 8;
    
    }
   
  }
  @media   screen and (min-height: 800px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 90px;
        margin:6px 6px;
    
    }
   
  }