.navbar{
    font-size: 20px
}

@media   screen and (min-height: 48px) {
    .navbar {
      font-size: 6px;
    }
  
   
  }
@media   screen and (min-height: 64px) {
    .navbar {
      font-size: 8px;
    }
  
   
  }

  @media   screen and (min-height: 96px) {
    .navbar {
      font-size: 8px;
    }
  
   
  }

  @media   screen and (min-height: 128px) {
    .navbar {
      font-size: 14px;
    }
   
   
  }
@media   screen and (min-height: 192px) {
    .navbar {
      font-size: 16px;
    }
 
  }
  @media   screen and (min-height: 256px) {
    .navbar{
      font-size: 16px;
    }
 
   
  }

  @media   screen and (min-height: 320px) {
    .navbar{
      font-size: 18px;
    }
  
   
  }
  @media   screen and (min-height: 384px) {
    .navbar {
      font-size: 20px;
    }
  
   
  }
  @media   screen and (min-height: 448px) {
    .navbar{
      font-size: 22px;
    }
 
   
  }
  @media   screen and (min-height: 512px) {
    .navbar {
      font-size: 24px;
    }
 
   
  }
  @media   screen and (min-height: 600px) {
    .navbar{
      font-size: 26px;
    }
 
   
  }
  @media   screen and (min-height: 700px) {
    .navbar{
      font-size: 28px;
    }
 
   
  }
  @media   screen and (min-height: 800px) {
    .navbar {
      font-size: 30px;
    }
 
  }