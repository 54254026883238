
.titleBar{
   
    display: flex;
    align-items: center;
    background-color:#02143085 ;
    height: 10%;
    color: whitesmoke;
    justify-content: space-around;

}

.title--Bar{
    font-size: 18px
}

@media   screen and (min-height: 48px) {
    .title--Bar {
      font-size: 6px;
    }
 
}
@media   screen and (min-height: 64px) {
    .title--Bar {
      font-size: 8px;
    }

  }

  @media   screen and (min-height: 96px) {
    .title--Bar {
      font-size: 12px;
    }

   
  }

  @media   screen and (min-height: 128px) {
    .title--Bar {
      font-size: 14px;
    }

   
  }
@media   screen and (min-height: 192px) {
    .title--Bar {
      font-size: 14px;
    }

   
  }
  @media   screen and (min-height: 256px) {
    .title--Bar {
      font-size: 16px;
    }

  }

  @media   screen and (min-height: 320px) {
    .title--Bar {
      font-size: 18px;
    }
 
  }
  @media   screen and (min-height: 384px) {
    .title--Bar {
      font-size: 20px;
    }

   
  }
  @media   screen and (min-height: 448px) {
    .title--Bar {
      font-size: 22px;
    }

   
  }
  @media   screen and (min-height: 512px) {
    .title--Bar {
      font-size: 26px;
    }
 
   
  }
  @media   screen and (min-height: 600px) {
    .title--Bar {
      font-size: 28px;
    }

   
  }
  @media   screen and (min-height: 700px) {
    .title--Bar {
      font-size: 28px;
    }

   
  }
  @media   screen and (min-height: 800px) {
    .title--Bar {
      font-size: 28px;
    }
   
   
  }